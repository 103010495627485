/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
body,html
  margin: 0
  padding: 0

.cdk-overlay-pane
  max-width: none !important
  
.text-center
  text-align: center

.text-right
  text-align: right

.width-100
  width: 100%

.mat-sidenav-content
  overflow-x: hidden !important

@media only screen and (min-width: 360px)
  .mat-dialog-container
    width: 378px !important

@media only screen and (max-width: 360px)
  .mat-dialog-container
    width: 320px !important
    overflow-x: auto

@media only screen and (max-width: 320px)
  .mat-dialog-container
    width: 260px !important
    overflow-x: auto

@media only screen and (max-width: 260px)
  .mat-dialog-container
    width: 220px !important
    overflow-x: auto

.mat-select-panel mat-option.mat-option
  height: unset

.mat-option-text.mat-option-text
  white-space: normal

.mat-simple-snackbar
  font-size: 1.2em
  color: white

.snackbar-error
  background-color: red

.snackbar-success
  background-color: green

.snackbar-info
  background-color: blue

.cards-container
  padding: 16px

.card-title
  min-height: 40px
  overflow: hidden
  word-break: break-all

.card-content
  overflow: auto
  height: 200px
  padding: 10px

.order-bell
  color: red

.action-buttons-container
  display: flex
  justify-content: space-between

.product-list
  margin-bottom: 10px


.fab-action
  top: auto !important
  right: 20px !important
  bottom: 10px !important
  left: auto !important
  position: fixed !important

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop
  background: #000
  opacity: 0.6 !important

.mat-datepicker-toggle
  .mat-icon-button
    color: #000000 !important

.mat-card
  .datePicker
    input
      color: #000000 !important
    .mat-form-field-label
      color: rgba(0,0,0,.6)
    .mat-form-field-outline
      color: rgba(0,0,0,.12)

video
  width: 100%
  height: auto